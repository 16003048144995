import { ServersArray } from "../../routes/config.ts"


// Interfaces for Server and PanelRightHeaderProps
interface Server {
  url: string;
  description: string;
}

interface PanelRightHeaderProps {
  servers: Server[];
  selectedServer: Server | null;
  onServerChange: (server: Server) => void;
}

// Main PanelRightHeader component
export default function PanelRightHeader({
  servers, 
  selectedServer, 
  onServerChange 
}: PanelRightHeaderProps) {

  // Handle server selection change
  const handleServerChange = (event: Event) => {
    const selectedValue = (event.target as HTMLSelectElement).value;
    const selectedServer = servers.find(server => server.url === selectedValue);
    if (selectedServer) {
      onServerChange(selectedServer);
    }
  };

  return (
    <div class="sticky top-0 z-10 bg-white p-4 flex justify-start items-center shadow-md">
      <label class="mr-2 font-bold">Available Server:</label>
      <select 
        class="border rounded-xl px-4 py-2 cursor-pointer w-full sm:w-auto"
        value={selectedServer ? selectedServer.url : ''}
        onChange={handleServerChange}
      >
        <option value="" disabled>Select a server:</option>
        {servers.map((server, index) => (
          <option key={index} value={server.url}>
            {server.url} ({server.description})
          </option>
        ))}
      </select>
    </div>
  );
}
